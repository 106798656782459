.contact {
  width: 80%;
  height: 90vh;
  display: flex;
  margin: auto;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact .rightSide span {
  color: rgb(249, 253, 13);
}
.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
  text-align: center;
}

form input {
  height: 40px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
  margin: auto;
}
.s {
  width: 20%;
}
form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
  margin: auto;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;

  font-size: larger;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: #d8bc6c;
  font-size: 15px;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .contact {
    width: 100%;
  }
  .leftSide{
    display: none;
  }
}

