.footer {
  width: 100%;
  height: fit-content;
/*   background-color: #FFF8DF; */
    justify-content: center;
  align-items: center;
  text-align: center;
/*   padding: 15px 0; */
  background-color: #0d0909;


}
iframe {
  width: 100%;
  height: 30vh;
}
.Map {
  width: 50%;
}
.f-a {
  width: 50%;
}
.container {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: auto;
}
p {
  margin: 5px;
  font-size: 15px;
}
h1 {
  margin: 5px;
  font-size: 30px;
}
@media only screen and (max-width: 650px) {
  iframe {
    width: 100%;
  }
  .f-a {
    width: 100%;
  }
  .Map {
    width: 100%;
  }
  .container {
    width: 100%;
    flex-direction: column;
  }
}