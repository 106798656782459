.bg {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  font-family: "Lobster";
}


.headerContainer {
  width: auto;
  margin-left: 50px;
  position: absolute;
  z-index: 1;
}

.headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-size: 90px;
  font-weight: bold;

  -webkit-text-stroke: 3px rgb(56, 49, 49);
}

.headerContainer p {
  padding: 0;
  font-size: 40px;
  font-weight: bold;


  margin: 20px 0;
}

.headerContainer button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  background-color: #121619;
  height: 50px;
  width: 180px;
  color: #d8bc6c;
  text-decoration: none;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #d8bc6c;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.slick-dots{
  top: 430px;
  position: absolute;
  z-index: -1;
  height: 20px;

}

@media only screen and (max-width: 650px) {
  .bg{
    height: 30vh;
  }
  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 

  }

  .headerContainer h1 {
    font-size: 40px;
    -webkit-text-stroke: 1px rgb(56, 49, 49);

  }

  .headerContainer p {
    font-size: 28px;
    -webkit-text-stroke: 1px rgb(56, 49, 49);
  }

  .headerContainer button {
    background-color: #121619;
    color: #d8bc6c;
  }

  .headerContainer button:hover {
    background-color: rgb(225, 225, 225);
    color: #121619;
  }
}