.navbar {
  width: 100%;
  height: 100px;
  background-color: #121619;
  display: flex;
  flex-direction: row;
}

.navbar .leftSide {
  flex: 20%;
  height: 100%;
  display: flex;
  align-items: center;
/*   padding-left: 150px; */
}

.navbar .leftSide img {
  width: 70px;
}

.navbar .rightSide {
  flex: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .rightSide img {
 color: #d8bc6c;
 width: 80px;
 height: 80px;
}

.navbar a {
  color: #d8bc6c;
  text-decoration: none;
  margin: 20px;
}
.navbar .rightSide li{
  list-style-type: none;
  display: inline-block;
}

.navbar .rightSide .lii {
  display: none;
}
.navbar .rightSide svg {
  font-size: 40px;
}

.navbar #open {
  padding-left: 0px;
}
.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

.navbar #open .hiddenLinks {
  display: inherit;
  margin-left: 30px;
}

.navbar #close .hiddenLinks {
  display: none;
}

.navbar #open a {
  width: 70px;
  margin: 5px;
}
.hi {
  cursor: pointer;
}
.hi:focus {
  background-color: #d8bc6c;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: White;
}
.hide {
  display: none;
}
.show {
  position: absolute;
  z-index: 231231232;
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: #121619;
  text-align: center;
  padding-inline-start: 0;
}

.show li {

  margin: 10px 0;
}


@media only screen and (max-width: 1100px) {
  .navbar{
    height: 7vh;
  }
  .navbar .leftSide {
    padding-left: 50px;
  }
  .navbar .rightSide li {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 50px;
  }

  .navbar .rightSide button {
    display: inherit;
  }
  .navbar .rightSide {
    padding-right: 0;
  }
  .navbar .rightSide .lii {
    display:inline;
  }
}
